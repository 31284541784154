@use "../../styles/partials/general" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.home {
    .home__about-section {
        margin: 1rem;

        .home__list {
            font-style: italic;
        }
    }
}
@include tablet{
    .home {
        .home__about-section {
            margin: 2rem;
    
            .home__list {
                font-style: italic;
            }
        }
    }
}