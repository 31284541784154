@use "../../styles/partials/general" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.sites {
    .sites-title__container {
        margin: 0 1rem;
    }

    .sites-logo__container {
        display: flex;
        justify-content: space-between;
        margin: 1rem;

        .sites-logo {
            height: 8rem;
            width: 100%;

        }
    }

    .requirements {
        margin-left: 1rem;
    }

    .website-guide {
        text-align: center;
        border-top: solid 1px #CBF4FA;

        .website-guide__list {
            list-style: none;
        }

        a {
            text-decoration: none;
            color: #CBF4FA;
        }

        & form {
            margin-top: 1rem;

            & .tutorial__slider {
                width: 90%;
                text-align: center;
                overflow: hidden;
                margin: auto;

                & a {
                    display: inline-flex;
                    width: 1.5rem;
                    height: 1.5rem;
                    background: white;
                    text-decoration: none;
                    align-items: center;
                    color: #0E1619;
                    justify-content: center;
                    border-radius: 50%;
                    margin: 0 0 0.5rem 0;
                    position: relative;

                    &.active {
                        background: #0E1619;
                        color: #CBF4FA;
                    }
                }
            }

            & .tutorial__slides {
                display: flex;
                overflow-x: auto;
                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;
                -webkit-overflow-scrolling: touch;
                height: 20rem;
                margin-bottom: 2rem;

                & a {
                    background: none;
                    width: fit-content;
                }
            }

            .tutorial__slides::-webkit-scrollbar {
                width: 10px;
                height: 10px;
            }

            .tutorial__slides::-webkit-scrollbar-thumb {
                background: #CBF4FA;
                border-radius: 10px;
            }

            .tutorial__slides::-webkit-scrollbar-track {
                background: transparent;
            }

            .tutorial__slides>div {
                scroll-snap-align: start;
                flex-shrink: 0;
                width: 100%;
                height: 100%;
                margin-right: 50px;
                border-radius: 10px;
                background: #0E1619;
                transform-origin: center center;
                transform: scale(1);
                transition: transform 0.5s;
                position: relative;
                border: 1px solid white;
                justify-content: center;
                align-items: center;
                font-size: .75rem;

                & li {
                    margin: 1.5rem auto;
                    letter-spacing: 2px;
                }
            }

            .author-info {
                background: rgba(0, 0, 0, 0.75);
                color: white;
                padding: 0.75rem;
                text-align: center;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                margin: 0;
            }

            .author-info a {
                color: white;
            }

            img {
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }



        #ngrok-config-file {
            display: flex;
            text-align: left;
            border: #CBF4FA solid 1px;

            & ul {
                width: 35%;
                height: 100%;
                padding: 0;
            }

            #ngrok-config {
                margin-top: 0;
                margin-block-start: 0;
                margin-block-end: 0;
                font-size: .75rem;

                pre {
                    margin: 0;
                }
            }
        }


        .sites__input {
            @include textInput;
            margin: auto;
        }
    }

    .sites__submit {
        @include button;
        margin-top: 1rem;
        font-size: .75rem;
    }


    .sites-copy__container {

        @include copyContainer;
        font-size: .75rem;
        display: block;
        height: 100%;
        width: 55%;

        .sites-copy__button {
            @include copyButton;
            margin-top: 1.8rem;
        }
    }
}



@include tablet {
    .sites {
        .sites-title__container {
            margin: 0 1rem;
        }

        .sites-logo__container {
            display: flex;
            justify-content: space-between;
            margin: 2rem 1rem;

            & a {
                width: 50%;
                margin: 0 .5rem;

                .sites-logo {
                    height: 16rem;
                    width: 100%;

                }
            }
        }

        .requirements {}

        .website-guide {
            text-align: center;
            border-top: solid 1px #CBF4FA;

            .website-guide__list {
                list-style: none;
            }

            .tutorial__slider {
                width: 90%;
                text-align: center;
                overflow: hidden;
                margin: auto;
            }

            & .tutorial__slides {

                & span a {}
            }

            .tutorial__slides::-webkit-scrollbar {
                width: 10px;
                height: 10px;
            }

            .tutorial__slides::-webkit-scrollbar-thumb {
                background: #CBF4FA;
                border-radius: 10px;
            }

            .tutorial__slides::-webkit-scrollbar-track {
                background: transparent;
            }

            .tutorial__slides>div {
                scroll-snap-align: start;
                flex-shrink: 0;
                width: 100%;
                height: 100%;
                margin-right: 50px;
                border-radius: 10px;
                background: #0E1619;
                transform-origin: center center;
                transform: scale(1);
                transition: transform 0.5s;
                position: relative;
                border: 1px solid white;
                justify-content: center;
                align-items: center;
                font-size: 1.25rem;

                & li {
                    margin: 1.5rem auto;
                }
            }

            .tutorial__slides>div:target {
                /*transform: scale(0.8); */
            }

            .author-info {
                background: rgba(0, 0, 0, 0.75);
                color: white;
                padding: 0.75rem;
                text-align: center;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                margin: 0;
            }


            img {
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .tutorial__slider>a {
                display: inline-flex;
                width: 1.5rem;
                height: 1.5rem;
                background: white;
                text-decoration: none;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin: 0 0 0.5rem 0;
                position: relative;
            }

            .tutorial__slider>a:active {
                background: #0E1619;
                color: #CBF4FA;
            }

            .tutorial__slider>a:focus {
                background: #000;
            }

            #ngrok-config-file {
                display: flex;

                & ul {
                    padding: 2rem;
                }

                #ngrok-config {
                    margin-top: 0;

                    pre {
                        margin: 0;
                    }
                }
            }

            .sites__input {}
        }
    }

    .sites__submit {}

    .ngrok-config-list {}

    .sites-copy__container {

        @include copyContainer;
        font-size: 1.25rem;


        .sites-copy__button {}

    }
}