@font-face {
    font-family: 'Barlow Condensed';
    src: url(../../assets/fonts/Barlow_Condensed/BarlowCondensed-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Barlow Condensed';
    src: url(../../assets/fonts/Barlow_Condensed/BarlowCondensed-Italic.ttf) format("truetype");
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'Barlow Condensed';
    src: url(../../assets/fonts/Barlow_Condensed/BarlowCondensed-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
  }