@use "../../styles/partials/general" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;



.navbar {
    font-size: 1.1rem;
    padding: 1rem;
    display: flex;
    height: 3rem;
    justify-content: space-evenly;
    border-bottom: $border;

    & h1 {
        line-height: 3rem;
        margin-bottom: auto;
        margin-top: auto;
        display: inline-block;
        font-weight: 700;
        color: #FFFFFF;
    }


    & :link,
    :visited,
    :active {
        text-decoration: none;
        color: #CBF4FA;
        line-height: 3rem;
    }

    & :hover{
        font-weight: 700;
        color: #FFFFFF;
        }
        
    
    .navlink-text {
        display: inline-block;
       &:hover{
            border: 1px solid #FFFFFF;
        }
        

    }
   

    .hands-off-logo {
        width: 3rem;
        height: 3rem;
        &:hover{
            border: 1px solid #FFFFFF;
        }
    }
}