@use '../partials/variables' as *;

@mixin textInput{
    height: 1.5rem;
    border-radius: 1rem;
    padding: 0;
    box-sizing:border-box;
    display: block;
    margin: 2rem 1rem;
    padding-left: 1rem;
    background-color:#CBF4FA;
    color: #0E1619;
    @content;
}
@mixin copyContainer{
    display: flex;
    justify-content: center;
@content;
}
@mixin copyButton{
    height: 1.5rem;
    margin-top: 1.25rem;
    margin-right: .25rem;
    border: solid 4px #0E1619;
    border-radius: 15%;
    background:#CBF4FA;
    color:#0E1619;
    outline: inset #CBF4FA 1px;
    @content;
}
@mixin button{
        height: 2rem;
        width:50%;
        border: 3px solid #CBF4FA;
        background: #0E1619;
        color: #CBF4FA;
        border-radius: 10%;
        margin: auto;
        text-align: center;
        @content;
}
@mixin tablet{
    @media(min-width: $tablet__breakpoint){
        @content;
    }
}
@mixin desktop{
    @media(min-width: $desktop__breakpoint){
        @content;
    }
}