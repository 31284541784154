@use "../../styles/partials/general" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.privacypolicy-section{

    margin: 0 1rem;
    a{
        text-decoration: none;
        color: white;
    }
}