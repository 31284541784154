@use "../../styles/partials/general" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;
.PostForm__OBS-server{
    padding: 1rem;
    display:block;
    text-align:center;
    &.hidden{
        display:none;
    }
    & input{
        @include textInput;
        margin: 2rem auto;
        width: 75%;
    }
.PostForm__OBS--submit{
    height: 3rem;
    width: 100%;
    border-radius: 1rem;
    background: #0E1619;
    border: 5px solid #CBF4FA;
    color: #CBF4FA;
    margin-bottom: 1rem;
}
}
.YT-sign-in-form{
    text-align: center;
    margin: 5rem 0;
.YT-sign-in{
    width: 75%;
    height: 3rem;
    background: #0E1619;
    color: #CBF4FA;
    border: 5px solid #CBF4FA;
    border-radius: 1rem;
    display: flex;
    margin: auto;
    line-height: 2.5rem;
    text-wrap: nowrap;
    & img{  
        height: 1.25rem;
        margin: auto 0.5rem auto 0.5rem;
        display: block;
    }
    &.hidden{
        display:none;
    }
}
}
.video-copy__container{
    @include copyContainer;
    text-align:center;
    .video-copy__button{
     @include copyButton;
     margin-bottom: 1rem;
     font-size: .6rem;
    }
}
.PostForm__popup{
    text-align: center;
    .video-input{
        background: #0E1619;
        color: #CBF4FA;
        width: 75%;
        &::placeholder{
            color: #CBF4FA;
        }
    }
    #videoCategory{
        background: #0E1619;
        color: #0E1619;
        margin: auto;
        

    }
    .video-copy__container{
        @include copyContainer;
       & .video-copy__button{

         @include copyButton;
         font-size: .6rem;
        }
    }
    .PostForm__popup--close{
        position: absolute;
        right: 0;
    }
}
.PostForm__instructions{
    margin:auto;
    width: 75%;
    font-size: 1.5rem;
    & li{
        border: solid 1px #CBF4FA;
        border-radius: 1rem;
        padding-left: .5rem;
    }
}
@include tablet{

    .PostForm__OBS-server{
        padding: 2rem;
       
    .PostForm__OBS--submit{
        height: 3rem;
        width: 50%;
    }
    }
    .YT-sign-in-form{
    .YT-sign-in{
        width: 50%;
     
        & img{  

            height: 1.5rem;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 1rem;
            margin-right: 25%;
        }
    }
}
}