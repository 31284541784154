@use "../../styles/partials/general" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.footer {
    padding: 0 1rem;
    border: 5px solid #CBF4FA;
    border-radius: .5rem;
    font-size: 1.25rem;
    box-sizing: border-box;
    margin-top: 3rem;

    .footer__title {
        font-style: italic;
    }

    .footer__list {
        list-style: none;
        display: flex;
        justify-content: space-between;
        padding: 0;

        li {
            .footer__link{
                text-decoration: none;
                color: white;
                font-size: .9rem;
            }
        }
    }
}