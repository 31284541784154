@use "../../styles/partials/general" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.sign-in-form {
    display: flex;
    flex-direction: column;
    width: 100%;


    & .sign-in-form__username {

        @include textInput;

    }

    & .sign-in-form__password {
        @include textInput;
    }

    & .sign-in-form__login {
        @include button
    }
}

.signup-or-forgot__button {
    @include button;
    display: block;
}

.popup-content {
    width: 100%;
    height: fit-content;
    background: #0E1619;
}

.signup-or-forgot__popup {
    background: #0E1619;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
    .signup-or-forgot__popup--close{
        position: absolute;
        right: 0;
    }

    .signup-or-forgot__input {
        @include textInput;
      
    }
    .signup-or-forgot__submit{
        @include button;
    }

}

@include tablet {
    .sign-in-form {
        & .sign-in-form__username {
            margin: 2rem auto;
            width: 75%;
        }

        & .sign-in-form__password {
            margin: 2rem auto;
            width: 75%;
        }
    }
}