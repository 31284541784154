@use "../../styles/partials/general" as *;
@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.reset-form{
    text-align: center;
    .reset-input{
        @include textInput;
        margin: 2rem auto;
        width: 75%;
    }
    .reset-submit{
        @include button;
    }
}